<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/bg_new_guide_video_nvip_area.webp" width="200" alt="">
    </div>
    <div class="center">
      <div style="font-weight: 900;font-size: 60px;" class="animate__animated animate__zoomInDown">QrLogin</div>
      <div class="animate__animated animate__lightSpeedInLeft" style="font-weight: 600;font-size: 30px;margin-top: 20px;--animate-duration:0.5s">扫码系统</div>
      <div style="margin-top: 40px">
        <el-row style="margin-bottom: 20px;" class="animate__animated animate__bounceInUp">
          <button class="button" @click="register" style="margin: 0 15px"><span>立即注册</span></button>
          <button class="button" @click="login" style="margin: 0 15px"><span>登录系统</span></button>
        </el-row>
      </div>

<!--      <el-row type="flex" justify="center" style="margin-top: 40px">-->
<!--        <el-col :xs="20" :sm="18" :md="15" :lg="10" :xl="8">-->
<!--          <div style="font-size: 14px;">-->
<!--            曝光一个傻逼，名叫XiaoMo，此人纯种小学生，多次抄袭我代码，以及各种功能，没能力只会复制你爹粘贴的狗，这个傻逼的东西全是抄袭的，-->
<!--            也是死全家了，之前还求我给他接口，不给他接口群聊几千人就没解析用了，-->
<!--            这个傻逼后面不知道报恩，倒咬一口，所以说是纯种小学生不为一点过分。据我了解，他之前还有一位导师教了他东西，后面直接被咬了，才出了这个逆子。-->
<!--            逆子的儿子叫千幻云，这个傻逼也是个傻逼，喜欢夸大事实，捏造事实，他爹是那个逆子，所有的东西也是求哪个逆子买的。-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->

<!--      <div class="footer">-->
<!--        <p>系统咨询找QQ：{{ qq }}</p>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
import 'animate.css';

export default {
  data() {
    return {
      qq: "",
      activeNames: ['1'],
    }
  },
  mounted() {
    document.body.style.overflowX = 'hidden';
    document.body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    // 恢复 body 的样式
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  },
  created() {
    this.getSysConfig()
  },
  methods: {
    async getSysConfig() {
      try {
        const response = await this.$request.get('/api/sysConfig');
        if (response.data.error === 0) {
          this.qq = response.data.qq
        } else {
          this.$message.error(response.data.msg);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    register() {
      this.$router.push('/reg');
    },
    login() {
      let user = localStorage.getItem("account");
      if (user !== "admin") {
        this.$router.push('/login');
      } else {
        this.$router.push('/admin');
      }
    }
  }
}
</script>

<style scoped lang="less">

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: #000000a6;
  font-size: 14px;
}

.button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #1875FF;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 56px -11px #1875FF;
  width: 10em;
  padding: 1em;
  transition: all 0.4s;
  cursor: pointer;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.button span:after {
  content: 'Go !';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.7s;
}

.button:hover span {
  padding-right: 2.55em;
}

.button:hover span:after {
  opacity: 4;
  right: 0;
}

.container {
  margin: 0 auto;
  text-align: center;

  .center {
    margin-top: 20px;

    .tip {
      background-color: #0000000d;
      border-radius: 20px;
      padding: 10px;
    }
  }

  .head {
    background-image: url("@/assets/svg/bbburst.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
  }
}
</style>