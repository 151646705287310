import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/style/element-variables.scss'
import 'normalize.css/normalize.css';
import App from './App.vue'
import router from './router'
import request from '@/api/api'


Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'medium', zIndex: 3000 });

Vue.prototype.$request = request

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

